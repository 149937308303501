import { Component } from 'react';
import { ConfigProvider } from 'antd4'
import { IntlProvider } from 'react-intl'
// import DPConfigProvider from '@dp-ui/components/DPConfigProvider'
import { connect } from 'react-redux'
import english from '../../../locales/en-US'
import englishUK from '../../../locales/en-GB'
// import french from 'locales/fr-FR'
// import russian from 'locales/ru-RU'
// import chinese from 'locales/zh-CN'
import swedish from '../../../locales/sw-SW'
import getUserFilter from '../../../lib/Methods/filter'

// addLocaleData(english.localeData)
// addLocaleData(french.local/eData)
// addLocaleData(russian.localeData)
// addLocaleData(chinese.localeData)
// addLocaleData(swedish.localeData)

const locales = {
  'en-US': english,
  'en-GB': englishUK,
  // 'fr-FR': french,
  // 'ru-RU': russian,
  // 'zh-CN': chinese,
  'sv-SE': swedish,
}

@connect(({ settings, filters }) => ({ settings, selectedLocale: getUserFilter(filters, 'selectedLocale') }))
class Localization extends Component
{

  render()
  {

    const {
      children,
      settings: { locale = 'en-US' },
      selectedLocale,
    } = this.props
    const localeForAntd = locale === 'en-US' ? 'en-GB' : 'sv-SE'
    const currentLocale = locales[selectedLocale || locale] || locales['sv-SE']
    const finalAntDLocale = locales[localeForAntd]
    return (
      <ConfigProvider locale={finalAntDLocale.antdData}>
        <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
          {children}
        </IntlProvider>
      </ConfigProvider>
    )

  }

}

export default Localization
