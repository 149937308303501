import { PureComponent } from 'react';
import { Layout } from 'antd4'
import { withRouter } from 'react-router-dom'
import styles from './style.module.scss'

@withRouter
class LoginLayout extends PureComponent
{

  state = {
    backgroundNumber: 1,
    backgroundEnabled: false,
  }

  changeBackground = () =>
  {

    const { backgroundNumber } = this.state
    this.setState({
      backgroundEnabled: true,
      backgroundNumber: backgroundNumber === 5 ? 1 : backgroundNumber + 1,
    })

  }

  toggleBackground = () =>
  {

    const { backgroundEnabled } = this.state
    this.setState({
      backgroundEnabled: !backgroundEnabled,
    })

  }

  render()
  {

    const { children } = this.props

    return (
      <Layout>
        <Layout.Content>
          <div className={styles.content}>{children}</div>
        </Layout.Content>
      </Layout>
    )

  }

}

export default LoginLayout
