/* eslint-disable import/no-cycle */
import { ApolloLink, ApolloClient, concat, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error/index";
import { notification } from "antd4";
import { store } from "..";
import { serverLink, redirectUri } from "./config";

export const clientLink = window.location.origin;

const httpLink = createUploadLink({
	uri: serverLink,
	headers: { "Apollo-Require-Preflight": "true" },
});

const defaultOptions = {
	watchQuery: {
		fetchPolicy: "network-only",
		errorPolicy: "ignore",
	},
	query: {
		fetchPolicy: "network-only",
		errorPolicy: "all",
	},
};

const onErrorGraphql = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		const dontNotify = ["email or passowrd is not correct"];
		graphQLErrors.map(({ message }) => {
			if (!dontNotify.includes(message.toLocaleLowerCase())) {
				const userObj = JSON.parse(global.localStorage.getItem("userInfo") || "{}");
				if (userObj?.beta) {
					if (!message.startsWith("Invalid timeIntervals parameter")) {
						notification.error({ message });
					}
				}
			}
			if (
				["session", "Not authenticated as user"].includes(message) ||
				message.toLocaleLowerCase().includes("session expired")
			) {
				const parentEditorRef = store.getState().parentEditor?.reference;
				if (parentEditorRef && parentEditorRef.openLoginModal) {
					parentEditorRef.openLoginModal({});
				}
			}
			return null;
		});
	}
	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authMiddleware = new ApolloLink((operation, forward) => {
	// add the authorization to the headers
	const userObj = JSON.parse(global.localStorage.getItem("userInfo"));
	const dbName = global.sessionStorage.getItem("datbaseName");
	const dbId = global.sessionStorage.getItem("datbaseId");
	const widgetDb = global.sessionStorage.getItem("widgetDB");
	if (operation.variables && operation.operationName !== "UploadFile") {
		operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
	}
	operation.setContext({
		headers: {
			authorization: userObj?.token ? `Bearer ${userObj?.token}` : "",
			account: dbName || "",
			database_id: dbId || "",
			widgetDbName: widgetDb || "",
		},
	});

	return forward(operation);
});

function omitTypename(key, value) {
	return key && key === "__typename" ? undefined : value;
}
const client = new ApolloClient({
	link: concat(authMiddleware, onErrorGraphql.concat(httpLink)),
	cache: new InMemoryCache(),
	defaultOptions,
});

export default client;

export { serverLink, redirectUri };
