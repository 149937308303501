// extracted by css-extract-rspack-plugin
export default {"layout":"src-layouts-Login-style-module__layout-oioYTn","light":"src-layouts-Login-style-module__light-JTvQBk","content":"src-layouts-Login-style-module__content-bLmm9C","navigationItems":"src-layouts-Login-style-module__navigationItems-YCEC2y","navigationActive":"src-layouts-Login-style-module__navigationActive-LkSLsk","footer":"src-layouts-Login-style-module__footer-l5Y7rU","header":"src-layouts-Login-style-module__header-oZ903V","logo":"src-layouts-Login-style-module__logo-S86fv5","controls":"src-layouts-Login-style-module__controls-QqNP78","navigation":"src-layouts-Login-style-module__navigation-A822Fs"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"layout\":\"src-layouts-Login-style-module__layout-oioYTn\",\"light\":\"src-layouts-Login-style-module__light-JTvQBk\",\"content\":\"src-layouts-Login-style-module__content-bLmm9C\",\"navigationItems\":\"src-layouts-Login-style-module__navigationItems-YCEC2y\",\"navigationActive\":\"src-layouts-Login-style-module__navigationActive-LkSLsk\",\"footer\":\"src-layouts-Login-style-module__footer-l5Y7rU\",\"header\":\"src-layouts-Login-style-module__header-oZ903V\",\"logo\":\"src-layouts-Login-style-module__logo-S86fv5\",\"controls\":\"src-layouts-Login-style-module__controls-QqNP78\",\"navigation\":\"src-layouts-Login-style-module__navigation-A822Fs\"}";
        // 1736933115627
        var cssReload = require("../../../node_modules/.pnpm/@rspack+core@1.1.8_@swc+helpers@0.5.15/node_modules/@rspack/core/dist/cssExtractHmr.js").cssReload(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  